<template>
  <div class="property-summary">
    <!-- Loading State -->
    <div v-if="isLoading" class="d-flex">
      <div class="skeleton property-image h-100"></div>
      <div class="w-100 ml-2">
        <div class="d-block property-title col-12 skeleton mb-1"></div>
        <p class="d-block col-2 skeleton my-1"></p>
        <p class="d-block col-8 skeleton mb-1"></p>
        <p class="d-block col-8 skeleton mb-1"></p>
      </div>
    </div>
    <!--  -->
    <div v-else class="d-flex" :class="{ exclusive: property.isExclusive }">
      <img
        :src="$getFileURL(property.mainPhoto[0])"
        class="property-image"
        :alt="property.name"
        @error="$setAltPhoto"
        loading="lazy"
      />
      <div class="ml-2">
        <div class="d-flex align-items-center">
          <exclusive-badge v-if="property.isExclusive">EX</exclusive-badge>
          <span class="property-title">
            {{ property.title }}
          </span>
        </div>

        <span v-if="purpose" class="d-block sub-info my-1">
          <span class="purpose">{{ property.purpose }}</span>
        </span>
        <p v-if="address" class="sub-info">
          {{ getPropertyAddress(property) }}
        </p>
        <span v-if="property.agencyUser" class="d-block sub-info">
          Agent <span class="font-bold">{{ property.agencyUser.name }}</span>
        </span>
        <span v-if="owner" class="d-block sub-info">
          Owned By <span class="font-bold">{{ property.owner.name }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getPropertyAddress } from "@/utils/property";

export default {
  components: {
    ExclusiveBadge: () =>
      import("@/components/GlobalComponents/Shared/Property/ExclusiveBadge")
  },
  mixins: [],
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    property: {
      type: Object,
      required: true
    },
    owner: {
      type: Boolean,
      default: false
    },
    address: {
      type: Boolean,
      default: false
    },
    purpose: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      getPropertyAddress
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.property-summary {
  .exclusive {
    .property-image {
      box-sizing: border-box;
      border-width: 5px;
      border-style: solid;
      border-image: linear-gradient(
        to right bottom,
        #b38728,
        #fcf6ba,
        #bf953f,
        #fbf5b7,
        #aa771c
      );
      border-image-slice: 1;
    }
  }

  .property-title {
    font-size: 16px;
  }
  .sub-info {
    color: #666;
    font-weight: normal;
  }
  .purpose {
    background: linear-gradient(
      to right,
      $color-accent2,
      lighten($color-accent2, 15)
    );
    color: white;
    padding: 2px 12px;
    border-radius: 50em;
    font-size: 11px;
    font-weight: bold;
  }
  .property-image {
    aspect-ratio: 1;
    width: 100px;
    border-radius: 8px;
    object-fit: cover;
  }
}
</style>
